import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  type: "",
  timestamp: -1,
  status: "",
};

const snackbar = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSnackbar(state, action) {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.timestamp = new Date().getTime();
      state.status = action.payload.status;
    },
  },
});

export const { showSnackbar } = snackbar.actions;
export default snackbar.reducer;
